*{
  margin: 0 ;
  padding: 0 ;
}
.hero{
  height: 100%;
  width: 100%;
  background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4) ),url('/public/images/bg2.png');
  background-position: center;
  background-size: cover;
  position: absolute;
      
}
.form-box{
width: 380px;
  height: 480px;
  position:relative;
  margin: 9% auto;
  background: linear-gradient(to right,white,white);
  padding: 5px;
  overflow: hidden;
  border-radius: 10px;
}
.button-box{
  width: 220px;
  margin:35px auto; 
  position: relative;
  box-shadow:  0 0 20px 9px #0000001f;
  border-radius: 5px;
  
}
.toggle-btn{
 padding: 10px 30px;
 align-items: center;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  position: relative;
  margin-left: 25%;
  
}

#btn{
  top: 0;
  left: 0;
  position: absolute;
  width: 220px;
  height: 100%;
  background: linear-gradient(to right,#10c7ff,#065dff);
  border-radius: 5px;
  transition: .5s; 
  
}
.social-icons {
  margin: 30px auto;
  text-align: center;
}

.social-icons img{
  width: 30px ;
  margin: 0 12px;
  box-shadow: 0 0 20px 0 #7f7f7f3d;
  cursor: pointer;
  border-radius: 5px
  
}
.input-group{
 top: 180px;
 position: absolute;
 width: 90%;
 transition: .5s;
}
.input-field{
  width:100%;
  padding: 10px 0;
  margin: 5px 0;
  margin-left: 10px;
  border-left: 0px;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid;
  outline: none;
  background:transparent;
}
.submit-btn{
  width: 100%;
  padding: 10px 30px;
  cursor: pointer;
  display: block;
  margin: auto;
  background: linear-gradient(to right,#10c7ff,#065dff);
  border: 0;
  outline: none;

  margin-top: 30px;
} 

.check-box{
  margin: 30px 10px 30px 0;
}
span{
  color: #777;
  font-size: 12px;
  bottom: 68px;
  position: absolute;
}
#Login{
  left: 50px
}
#Signup{
  left: 450px
}
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}



.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.50) 2.4px 2.4px 3.2px;
}

.sidebar a {
  display: block;
  color: black;
 
  padding: 10px;
  text-decoration: none;
}
 
.sidebar a:active {
  background-color: #04AA6D;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: green;
  color: white;
}

div.content {
  
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {float: left;}
  div.content {margin-left: 0;}
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
.welcome{
  size: 100px;
}
i:hover{
   cursor: pointer;
}
.homeimages{
  height: 50px;
  width: 50px;
}
.addimage{
  text-align: left;
  margin-left: 20px;
}



.bell-icon {
  position: relative;
  display: inline-block;
  margin-right: 20px;

}

.popup-modal {
  position: absolute;
  top: 30px;
  right: 0;
  width: 400px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  padding: 20px;
  scroll-behavior: smooth;
  
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  height: 40vh;
  
  
}
@media screen and (max-width: 549px) {
  .popup-modal {
    position: absolute;
    top: 30px;
    left: 10;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    padding: 20px;
    scroll-behavior: smooth;
    
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    height: 40vh;
    
    
  }
}
.popup-modal h2 {
  margin-top: 0;
}

.popup-modal ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.popup-modal li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
 
}

.popup-modal li:last-child {
  border-bottom: none;
  
}

tbody{
  background: lightgray;

}
.deletericonmdel{
  margin-left: 30px;
  margin-top: 50px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.page-item {
  margin: 0 0.5rem;
}

.page-link {
  background-color: #fff;
  border: 1px solid #dee2e6;
  color: #212529;
}

.page-link:hover {
  background-color: #f0f0f0;
}

.page-link:focus {
  box-shadow: none;
}

.page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

.page-item.disabled .page-link {
  background-color: #f0f0f0;
  border-color: #dee2e6;
  color: #6c757d;
}

.outer-wrapper {
  margin: 10px;
 
  
  border-radius: 4px;
  
  max-width: 100%;
  max-height: fit-content;

  
}
.table-wrapper {

  overflow-y: scroll;
  overflow-x: scroll;
  height: fit-content;
  max-height: 66.4vh;
  max-width: 100%;
  margin-top: 22px;
  
  
  padding-bottom: 20px;
  
}


table {
  
  
  
  border-collapse: separate;
  border-spacing: 0px;  
 
  
}



table th{
  
  top: 0px;
  
  background-color: #133b5c;
  color: rgb(241, 245, 179);
  
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  color: white;

} 



table th, table td {
  
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 1;
}

table td {
  text-align: left;
  
  font-size: 15px;
  border: 1px solid rgb(177, 177, 177);
  padding-left: 20px;
  
}